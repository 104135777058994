const initialState = {
    banner: false,
    allCategories: [],
    mainCategories: [],
    selectedCategory: null,
    currentCategoryData: {
        provider: '',
        id: ''
    },
    currentCategory: null,
    categoryName: '',
    headerCurrentCategory: {
        provider: '',
        id: ''
    },
};

export default initialState;