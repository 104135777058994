import React from 'react';
import {Link, useLocation} from "react-router-dom";

const CookiePopup = ({visible, handleVisibleCookiePopup}) => {
    const location = useLocation();
    const supportsWebP = (() => {
        const elem = document.createElement('canvas');
        return elem.toDataURL('image/webp').startsWith('data:image/webp');
    })();

    return (
        <div className={`cookie-popup ${visible ? 'visible-popup' : ''}`} style={supportsWebP ?
            {
                backgroundImage: 'url(/images/bg/cookie-popup-bg.webp)',
                display: location.pathname === '/cookie' ? 'none' : 'flex'
            } :
            {
                backgroundImage: 'url(/images/bg/cookie-popup-bg.png)',
                display: location.pathname === '/cookie' ? 'none' : 'flex'
            }
        }>
            <div className="container">
                <div className="cookie-popup-wrap">
                    <h3>This website uses cookies in order to offer you the most relevant information. Please accept cookies for optimal performance</h3>
                    <button className='accept-cookie' onClick={handleVisibleCookiePopup}>ACCEPT ALL COOKIES</button>
                </div>
            </div>
        </div>
    );
}

export default CookiePopup;