import React from 'react';

const ErrorFallback = ({ error }) => {
    return (
        <>
            <div className='home-bg-container error-handler-container'>
                <h3>Ooops...</h3>
                <div>Something went wrong: {error.message}.<br />Please try again later.</div>
            </div>
        </>
    )
};

export default ErrorFallback;