import React from 'react';

const Banner = ({titleText}) => {
    const supportsWebP = (() => {
        const elem = document.createElement('canvas');
        return elem.toDataURL('image/webp').startsWith('data:image/webp');
    })();

    return (
        <div className="axil-main-slider-area main-slider-style-7 main-banner" style={supportsWebP ? {backgroundImage: 'url(/images/banner/banner-bg.webp)'} : {backgroundImage: 'url(/images/banner/banner-bg.png)'}}>
            <div className="container">
            <div className="row align-items-center">
                    <div className="main-slider-content">
                        <h1 className="title">{titleText}</h1>
                        <div className="shop-btn">
                            <a href="#main" className="axil-btn btn-bg-secondary right-icon">
                                SHOP NOW
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner;
