import React, {useState, useEffect} from 'react';
import {useLocation, useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";
import GetProductsDataScroll from "../../hooks/get-products-scroll";
import extractUrlParams from "../../helpers/extract-url-params";
import {findCategoryNameById} from "../../helpers/find-category-name-by-id";
import getProductProps from "../../helpers/get-product-props";
import {PRODUCT_MAIN} from "../../constants";

const Products = ({categories, data, setData, triggerLoadingMoreProducts, loadingProducts, setLoadingProducts, hasMore, setHasMore, dataPage, setDataPage}) => {
    const [queryParameters] = useSearchParams();
    const {pathname} = useLocation();
    const allCategories = useSelector(state => state.dataUploader.allCategories);
    const mainCategories = useSelector(state => state.dataUploader.mainCategories);
    const currentCategory = useSelector(state => state.dataUploader.currentCategoryData);
    const selectedCategory = useSelector(state => state.dataUploader.selectedCategory);
    const headerCurrentCategory = useSelector(state => state.dataUploader.headerCurrentCategory);
    const categoryName = useSelector(state => state.dataUploader.categoryName);
    // eslint-disable-next-line no-unused-vars
    const [loading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showFullDescriptions, setShowFullDescriptions] = useState([]);
    const [isSubCategory, setSubCategory] = useState(false);
    const [categoryTitle, setCategoryTitle] = useState('');

    useEffect(() => {
        let id;
        if(headerCurrentCategory && headerCurrentCategory.id !== '') {
            id = headerCurrentCategory.id;
        } else if(currentCategory && currentCategory.id !== '') {
            id = currentCategory.id;
        } else if(mainCategories && mainCategories.length > 0) {
            id = mainCategories[0].id;
        }
        const item = findCategoryNameById(allCategories, id);
        const categoryName = localStorage.getItem('categoryName');
        if(categoryName && (headerCurrentCategory.id === '' || currentCategory.id === '')) {
            setCategoryTitle(categoryName);
        } else if(item && item.name) {
            setCategoryTitle(item.name)
            localStorage.removeItem('categoryName');
            localStorage.removeItem('categoryId');
            localStorage.removeItem('categoryProvider');
        }
        if (pathname === '/') {
            setCategoryTitle(item?.name)
        }
    }, [allCategories, currentCategory, headerCurrentCategory, mainCategories]);

    useEffect(() => {
        if(pathname === '/') {
            localStorage.removeItem('categoryName');
            localStorage.removeItem('categoryId');
            localStorage.removeItem('categoryProvider');
        }
    }, [pathname]);

    const getMoreData = async (page) => {
        window.removeEventListener('scroll', handleScroll);
        let id, provider;
        let {params, paramCategory} = extractUrlParams(queryParameters, 'products', page);
        const subCategoryId = localStorage.getItem('categoryId');
        const subCategoryProvider = localStorage.getItem('categoryProvider');
        if (paramCategory) {
            id = paramCategory;
            headerCurrentCategory.provider !== '' ? provider = headerCurrentCategory.provider : provider = currentCategory.provider;
        } else if (headerCurrentCategory && headerCurrentCategory.id !== '') {
            id = headerCurrentCategory.id;
            provider = headerCurrentCategory.provider;
        } else if(subCategoryId && subCategoryId !== '' && subCategoryProvider && subCategoryProvider !== '' && currentCategory.id !== '') {
            id = subCategoryId;
            provider = subCategoryProvider;
        } else if(!subCategoryId && !subCategoryProvider && currentCategory && currentCategory.id !== '') {
            id = currentCategory.id;
            provider = currentCategory.provider;
        }
        setLoadingProducts(true);
        setErrorMessage('');

        if (!loadingProducts) {
            GetProductsDataScroll(provider, setLoadingProducts, setError, setErrorMessage, data, setData, params, id, 'products', setHasMore);
        }
    }

    useEffect(() => {
        if(localStorage.getItem('categoryId')) {
            if(Object.keys(mainCategories).length > 0 && mainCategories.filter(el => +el.id === +localStorage.getItem('categoryId')).length > 0) {
                setSubCategory(false);
            } else {
                setSubCategory(true);
            }
        } else if(currentCategory.id !== '' && headerCurrentCategory.id === '') {
            if(Object.keys(mainCategories).length > 0 && mainCategories.filter(el => +el.id === +currentCategory.id).length > 0) {
                setSubCategory(false);
            } else {
                setSubCategory(true);
            }
        } else if(headerCurrentCategory && headerCurrentCategory.id !== '') {
            if(Object.keys(mainCategories).length > 0 && mainCategories.filter(el => +el.id === +headerCurrentCategory.id).length > 0) {
                setSubCategory(false);
            } else {
                setSubCategory(true);
            }
        }
    }, [mainCategories, headerCurrentCategory]);

    const handleScroll = () => {
        const isElementInViewport = (el) => {
            if (!el) return;
            const element = el.current;
            if (!element) return;
            let rect = element.getBoundingClientRect();

            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        }
        if (isElementInViewport(triggerLoadingMoreProducts)) {
            const dataPageCopy = dataPage;
            setDataPage(dataPage + 1);
            setLoadingProducts(true);
            getMoreData(dataPageCopy);
        }
    };

    const loadMore = () => {
        const dataPageCopy = dataPage;
        setDataPage(dataPage + 1);
        setLoadingProducts(true);
        getMoreData(dataPageCopy);
    }

    useEffect(() => {
        if (!loadingProducts && hasMore) {
            window.addEventListener('scroll', handleScroll);

            return () => {
                window.removeEventListener('scroll', handleScroll); // Clean up the event listener
            };
        }
    }, [data, categories, currentCategory, selectedCategory, headerCurrentCategory, hasMore, loadingProducts]) // eslint-disable-line react-hooks/exhaustive-deps

    const toggleDescription = (productId) => {
        setShowFullDescriptions(prevState => {
            const newState = {...prevState};
            newState[productId] = !newState[productId];
            return newState;
        });
    };

    if (error) {
        return (
            <>
                {categoryTitle ? <h3 className="title product-category-title">Best offers from {categoryTitle}</h3> : categoryName ? <h3 className="title product-category-title">Best offers from {categoryName}</h3> : null}
                <div className='products-error-msg'>{errorMessage ? errorMessage : error.message}</div>
            </>
        )
    }

    if (loading) {
        return <div className="products-loading">Loading...</div>;
    }

    return (
        <>
            {categoryTitle ? <h3 className="title product-category-title">Best offers from {categoryTitle}</h3> : categoryName ? <h3 className="title product-category-title">Best offers from {categoryName}</h3> : null}
            <div className="row row--15 products-inner-container">
                {
                    data.length > 0 && data.map((el, i) => {
                        const {id, brandName, title, productImage, productPrice, description, currencySymbol, brandImage} = el;
                        const {productFullLink, croppedTitle, showFullDescription, croppedDescription} = getProductProps(queryParameters, el, showFullDescriptions, PRODUCT_MAIN, currentCategory.provider);
                        return (
                            <div className="col-xl-3 col-lg-4 col-sm-6" key={id + i}>
                                <div className="product-inner-block axil-product product-style-one has-color-pick mt--10">
                                    {brandImage ?
                                        <img className="product-brand-image product-brand-image-desktop" src={brandImage}
                                             alt={brandName}/> :
                                        <div className='product-brand-image'></div>
                                    }
                                    <div className="thumbnail">
                                        <a href={productFullLink} target="_blank" rel="noreferrer">
                                            <img src={productImage} alt={title}/>
                                        </a>
                                        {window.innerWidth > 767 ?
                                            <div className="product-hover-action">
                                                <ul className="cart-action">
                                                    <li className="select-option">
                                                        <a href={productFullLink} target="_blank" rel="noreferrer">View Offer</a>
                                                    </li>
                                                </ul>
                                            </div> :
                                            null
                                        }
                                    </div>
                                    <div className="product-content">
                                        <div className="inner">
                                            {brandImage ?
                                                <img className="product-brand-image product-brand-image-mobile" src={brandImage}
                                                     alt={brandName}/> :
                                                <div className='product-brand-image'></div>
                                            }
                                            <h5 className="title">
                                                <a href={productFullLink} target="_blank" rel="noreferrer" className={croppedTitle !== title ? 'add-popup': ''}>{croppedTitle}</a>
                                                {croppedTitle !== title ?
                                                    <a className='title-popup' href={productFullLink} target="_blank" rel="noreferrer">{title}</a> :
                                                    null
                                                }
                                            </h5>
                                            <div className="product-content">
                                                <div className="inner">
                                                    <p>
                                                        {showFullDescription ?
                                                            description :
                                                            croppedDescription
                                                        }
                                                        {description.length > 30 && (
                                                            <span className="read-more">
                                                            {showFullDescription ? (
                                                                <button onClick={() => toggleDescription(id)}>Show
                                                                    less</button>
                                                            ) : (
                                                                <button onClick={() => toggleDescription(id)}>Show
                                                                    more</button>
                                                            )}
                                                        </span>
                                                        )}
                                                    </p>
                                                </div>
                                                <div className="product-price-variant">
                                            <span
                                                className="price current-price">{`${currencySymbol.length > 1 ? productPrice + ' ' + currencySymbol : currencySymbol + productPrice}`}</span>
                                                </div>
                                                {window.innerWidth <= 767 ?
                                                    <ul className="cart-action">
                                                        <li className="select-option">
                                                            <a href={productFullLink} target="_blank" rel="noreferrer">View Offer</a>
                                                        </li>
                                                    </ul> :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                {
                    loadingProducts ?
                        <div className="products-loader-container">
                            <span className="loader"></span>
                        </div> :
                        null
                }
                {!isSubCategory && hasMore && data.length > 0 ?
                    <div className="text-center pt--30" ref={triggerLoadingMoreProducts}>
                        <button className="axil-btn btn-load-more">Load more</button>
                    </div> :
                    isSubCategory && hasMore && data.length > 0 ?
                        <div className="text-center pt--30">
                            <button className="axil-btn btn-load-more" onClick={loadMore}>Load more</button>
                        </div> :
                        null
                }
            </div>
        </>
    );
}


export default Products;
