import React from 'react';
import {useSelector} from "react-redux";
import {Link, useSearchParams} from "react-router-dom";
import HeaderCategoriesDropdown from "../HeaderCategoriesDropdown";

const Header = ({data}) => {
    const allCategories = useSelector(state => state.dataUploader.allCategories);
    const mainCategories = useSelector(state => state.dataUploader.mainCategories);
    const [queryParameters] = useSearchParams();
    const queryParams = []
    if (queryParameters.get('gclid')) {
        queryParams.push('gclid=' + queryParameters.get('gclid'))
    }

    const removeTitle = () => {
        localStorage.removeItem('categoryName');
        localStorage.removeItem('categoryId');
        localStorage.removeItem('categoryProvider');
    }
    const removeSideMenu = e => {
        const wrap = e.target.closest('body');
        wrap.removeAttribute('style');
        wrap.classList.remove('open');
        wrap.querySelector('.header-main-nav').classList.remove('open');
        const closeMasks = wrap.querySelectorAll('.closeMask');
        closeMasks.forEach(function(mask) {
            mask.remove();
        });
    }

    return (
        <>
            <header className="header axil-header header-style-5">
                <div id="axil-sticky-placeholder"></div>
                <div className="axil-mainmenu">
                    <div className="container">
                        <div className="header-navbar">
                            <div className="header-brand">
                                <a href={"/" + (queryParams.length > 0 ? ('?' + queryParams.join('&')) : '')} onClick={removeTitle} className="logo logo-dark">
                                    <img src={`/images/logo/logo.png`} alt="Site Logo"/>
                                </a>
                            </div>
                            <div className="header-main-nav">
                                <nav className="mainmenu-nav">
                                    <button className="mobile-close-btn mobile-nav-toggler"><i
                                        className="fas fa-times"></i></button>
                                    <div className="mobile-nav-brand">
                                    <a href={"/" + (queryParams.length > 0 ? ('?' + queryParams.join('&')) : '')} onClick={removeTitle} className="logo">
                                            <img src={`/images/logo/logo-mobile.png`} alt="Site Logo"/>
                                        </a>
                                    </div>
                                    <ul className="mainmenu">
                                        <li><a href={"/" + (queryParams.length > 0 ? ('?' + queryParams.join('&')) : '')} onClick={removeTitle}>Home</a></li>
                                        {allCategories.length || mainCategories.length > 0 ?
                                            <HeaderCategoriesDropdown
                                                data={data}
                                                removeSideMenu={removeSideMenu}
                                            /> : null}
                                        <li onClick={e => removeSideMenu(e)}>
                                            <Link to={"/about" + window.location?.search}>About Us</Link>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            <div className="header-action">
                                <ul className="action-list">
                                    <li className="axil-mobile-toggle">
                                        <button className="menu-btn mobile-nav-toggler">
                                            <i className="flaticon-menu-2"></i>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header;
