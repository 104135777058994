import {getProductsService} from "../services/products";

const GetProductsDataScroll = async (provider, setLoading, setError, setErrorMessage, data, setData, params, categoryId, type, setHasMore, emergyParams) => {
    setLoading(true);
    setErrorMessage('');

    try {
        if (categoryId) params += `&category=${categoryId}`;
        if (emergyParams) {
            params += `&emergyParams=${JSON.stringify(emergyParams)}`;
        }
        const results = await getProductsService(provider, params);
        if(results['data'].errors.length > 0 || results['data']['products'].length === 0) {
            setHasMore(false);
            if(data.length > 0) {
                setError(false);
                setErrorMessage('');
                setData([...data]);
            } else {
                setLoading(false);
                setHasMore(false);
                return params;
            }
        } else {
            setHasMore(true);
            setError(false);
            setErrorMessage('');
            const newData = [...data, ...results['data'][type]];
            setData(newData)
        }
        setLoading(false);
        return params;
    } catch (error) {
        setLoading(false);
        setHasMore(false);
        return params;
    }
}

export default GetProductsDataScroll;
