import {baseFetch} from "./axios-instance/index";
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
let folder = urlParams.get('lid')
if (window.location.pathname.startsWith('/lpv/')) {
  folder = window.location.pathname.substring(5).slice(0, -1)
}

// export const getConfigCategoriesService = async () => await internalFetch.get(`${folder ? folder + '/' : ''}config.json`);
export const getConfigCategoriesService = async () => await baseFetch.get(`config/${folder ?? 'default'}/`);
export const getCategoryByIdService = async (provider, id) => await baseFetch.post(`${provider}/categories/${id}`);
