import {getConfigCategoriesService} from "../services/categories";

const GetConfigCategoriesData = async (setLoading, setError, setMainCategories, setCurrentCategoryData, setBanner, setDefaultCategoryData) => {
    setLoading(true);

    try {
        const results = await getConfigCategoriesService();
        if(results['data']) {
            if(setBanner) setBanner(results['data']['useMainBanner']);
            if(setMainCategories) setMainCategories(results['data']['mainCategories']);

            if(results['data']['currentCategory']) {
                setCurrentCategoryData(results['data']['currentCategory']);
            }
            if(results['data']['defaultCategory']) {
                setDefaultCategoryData(results['data']['defaultCategory']);
            }
        }
        setLoading(false);
    } catch (error) {
        setLoading(false);
        setError(true);
        // console.error(error);
    }
}

export default GetConfigCategoriesData;
