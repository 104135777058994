import {getProductsService} from "../services/products";

const GetProductsData = async (provider, setLoading, setError, setErrorMessage, setData, params, categoryId, type, setHasMore, dispatch, emergyParams) => {
    setLoading(true);
    setErrorMessage('');

    try {
        if(categoryId) {
            params += `&category=${categoryId}`;
            if (emergyParams) {
                params += `&emergyParams=${JSON.stringify(emergyParams)}`;
            }
            const results = await getProductsService(provider, params, emergyParams);
            if(!results['data'] || !results['data']['products'] || results['data']['products'].length === 0) {
                setError(true);
                setErrorMessage('Sorry! This category doesn\'t have any products!')
            } else {
                setError(false);
                setErrorMessage('');
                setData(results['data'][type]);
            }
            setLoading(false);
            if(setHasMore) setHasMore(true);

            return params;
        }
    } catch (error) {
        setLoading(false);
        setError(true);
        setErrorMessage('');
        console.error('Error!', error);
    }
}

export default GetProductsData;
