import React, {useEffect} from 'react';
import {LEGAL_PAGES} from "../../constants";
import {useSearchParams} from "react-router-dom";

const Legal = ({typeObj}) => {
    const [queryParameters] = useSearchParams();
    const queryParams = []
    if (queryParameters.get('gclid')) {
        queryParams.push('gclid=' + queryParameters.get('gclid'))
    }
    useEffect(() => {
        document.title = typeObj.title;
    }, [typeObj]);

    const removeTitle = () => {
        localStorage.removeItem('categoryName');
        localStorage.removeItem('categoryId');
        localStorage.removeItem('categoryProvider');
    }

    return (
        <main className="main-wrapper">
            <div className="axil-breadcrumb-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-8">
                            <div className="inner">
                                <ul className="axil-breadcrumb">
                                    <li className="axil-breadcrumb-item"><a href={"/" + (queryParams.length > 0 ? ('?' + queryParams.join('&')) : '')} onClick={removeTitle}>Home</a></li>
                                    <li className="separator"></li>
                                    <li className="axil-breadcrumb-item active" aria-current="page">{typeObj.title}</li>
                                </ul>
                                <h1 className="title">{typeObj.title}</h1>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="axil-privacy-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10">
                            <div className="axil-privacy-policy">
                                {typeObj.link === LEGAL_PAGES.PRIVACY.link ?
                                    <>
                                        <p>This Privacy Policy ("Policy") explains how we collect, use, disclose, and protect personal information obtained from visitors ("you" or "users") of our website  ("Website"). By accessing or using the Website, you agree to the terms of this Policy.</p>
                                        <h4 className="title">Information We Collect</h4>
                                        <p>We may collect the following types of information:</p>
                                        <ul>
                                            <li><b>Personal Information:</b> We may collect personal information, such as your name, email address, and contact details, when you voluntarily provide it to us through forms or communications on the Website.</li>
                                            <li><b>Usage Information:</b> We may automatically collect certain information about your use of the Website, such as your IP address, browser type, operating system, and browsing activity. This information helps us analyze and improve the Website's functionality.</li>
                                            <li><b>Cookies and Tracking Technologies:</b> We use cookies and similar tracking technologies to enhance your experience on the Website. You can control how cookies are used through your browser settings.</li>
                                        </ul>
                                        <h4 className="title">How We Use Your Information</h4>
                                        <p>We use the collected information for various purposes, including but not limited to:</p>
                                        <ul>
                                            <li>Providing you with access to the Website's content and functionality.
                                            </li>
                                            <li>Personalizing your experience on the Website.</li>
                                            <li>Analyzing and improving the Website's performance and user experience.
                                            </li>
                                            <li>Sending you marketing and promotional communications if you've opted in
                                                to receive them.
                                            </li>
                                            <li>Managing and administering our affiliate marketing program.</li>
                                            <li>Complying with legal obligations.</li>
                                        </ul>
                                        <h4 className="title">Disclosure of Your Information</h4>
                                        <p>We may share your information with third parties, including:</p>
                                        <ul>
                                            <li>Affiliate partners, as necessary for the proper functioning of our
                                                affiliate marketing program.
                                            </li>
                                            <li>Service providers who assist us in managing the Website and its
                                                features.
                                            </li>
                                            <li>Legal authorities or other third parties when required by law or to protect our rights and interests</li>
                                        </ul>
                                        <p>Your Choices</p>
                                        <ul>
                                            <li>You can opt out of receiving marketing communications from us by
                                                following the unsubscribe instructions in the emails we send you.
                                            </li>
                                            <li>You can disable cookies through your browser settings.</li>
                                        </ul>
                                        <h4 className="title">Security</h4>
                                        <p>We implement reasonable security measures to protect the information we collect from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>
                                        <h4 className="title">Third-Party Links</h4>
                                        <p>Our Website may contain links to third-party websites. We are not responsible for the privacy practices or content of these websites. We encourage you to review the privacy policies of these websites before providing them with your personal information.</p>
                                        <h4 className="title">Children's Privacy</h4>
                                        <p>Our Website is not intended for children under the age of 13. We do not knowingly collect personal information from individuals under 13 years of age. If you are a parent or guardian and believe your child has provided us with personal information, please contact us to have that information removed.</p>
                                        <h4 className="title">Changes to this Policy</h4>
                                        <p>We may update this Policy from time to time. The date of the latest revision will be indicated at the top of the Policy. We encourage you to review this Policy periodically for any changes.</p>
                                        <h4 className="title">Contact Us</h4>
                                        <p>If you have any questions or concerns about this Privacy Policy, please contact us.</p>
                                    </> :
                                    typeObj.link === LEGAL_PAGES.TERMS.link ?
                                        <>
                                            <span className="policy-published">Last Updated: September 1st, 2023.</span>
                                            <p>Welcome to our website. By accessing or using the Website, you agree to be bound by these Terms and Conditions ("Terms"). Please read these Terms carefully before using the Website. If you do not agree to these Terms, please refrain from using the Website.</p>
                                            <h4 className="title">Use of the Website</h4>
                                            <ul>
                                                <li><b>Eligibility:</b> You must be at least 18 years old to use the Website. If you are under 18, you may use the Website only with the involvement and consent of a parent or guardian.</li>
                                                <li><b>Compliance with Laws:</b> By using the Website, you agree to comply with all applicable laws and regulations. You shall not use the Website for any illegal or unauthorized purpose.</li>
                                                <li><b>Unauthorized Use:</b> You shall not engage in any unauthorized use of the Website, including but not limited to hacking, tampering with content, or attempting to gain unauthorized access to restricted areas.</li>
                                                <li><b>User Accounts:</b> Certain areas of the Website may require you to create an account. You are responsible for maintaining the confidentiality of your account credentials and for any activity that occurs under your account.</li>
                                            </ul>
                                            <h4 className="title">Intellectual Property</h4>
                                            <ul>
                                                <li><b>Ownership:</b> The content and materials on the Website, including but not limited to text, images, logos, graphics, and software, are protected by copyright, trademark, and other intellectual property laws. All rights not expressly granted are reserved.</li>
                                                <li><b>Limited License:</b> You may view and access the content on the Website for personal, non-commercial purposes. You may not reproduce, distribute, modify, or create derivative works based on this content without our prior written consent.</li>
                                            </ul>
                                            <h4 className="title">Disclaimer of Warranties</h4>
                                            <ul>
                                                <li><b>No Warranty:</b> The Website and its content are provided "as is" and without warranties of any kind, either express or implied. We do not guarantee the accuracy, completeness, or reliability of the content.</li>
                                                <li><b>Use at Your Own Risk:</b> You acknowledge that your use of the Website is at your own risk. We do not warrant that the Website will be error-free, secure, or free from viruses or other harmful components.</li>
                                            </ul>
                                            <h4 className="title">Limitation of Liability</h4>
                                            <ul>
                                                <li><b>Limitation of Liability:</b> To the fullest extent permitted by law, we shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of your use of the Website.</li>
                                                <li><b>Information Disclaimer:</b> We are not responsible for any actions taken based on the information provided on the Website. You are advised to independently verify information before making decisions.</li>
                                            </ul>
                                            <h4 className="title">Third-Party Links and Services</h4>
                                            <ul>
                                                <li><b>Third-Party Content:</b> The Website may contain links to third-party websites and services. We are not responsible for the content, privacy practices, or actions of these third parties. Your interactions with such third parties are at your own risk.</li>
                                                <li><b>Endorsement:</b> The inclusion of any third-party link or content on the Website does not imply our endorsement or affiliation with them.</li>
                                            </ul>
                                            <h4 className="title">Modification and Termination</h4>
                                            <ul>
                                                <li><b>Modification:</b> We reserve the right to modify, suspend, or terminate the Website or any part of it at any time without notice.</li>
                                                <li><b>Updated Terms:</b> We may update these Terms from time to time. The date of the latest revision will be indicated at the top of the Terms. Your continued use of the Website after such changes signifies your acceptance of the updated Terms.</li>
                                            </ul>
                                            <h4 className="title">Governing Law and Jurisdiction</h4>
                                            <p>These Terms shall be governed by and construed in accordance with the laws, without regard to its conflict of law principles. Any legal action or dispute arising from or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts.</p>
                                            <h4 className="title">Severability</h4>
                                            <p>If any provision of these Terms is found to be invalid or unenforceable, that provision shall be deemed severable and shall not affect the validity and enforceability of the remaining provisions.</p>
                                        </> :
                                        typeObj.link === LEGAL_PAGES.COOKIE.link ?
                                            <>
                                                <span className="policy-published">Last Updated: September 1st, 2023.</span>
                                                <p>This Cookie Policy ("Policy") explains how we use cookies and similar tracking technologies on our website. By using the Website, you consent to the use of cookies in accordance with this Policy. If you do not agree to the use of cookies, please disable them in your browser settings or refrain from using the Website.</p>
                                                <h4 className="title">What Are Cookies?</h4>
                                                <p>Cookies are small text files that are stored on your device when you visit a website. They serve various purposes, including remembering your preferences, analyzing website usage, and providing personalized content and ads.</p>
                                                <h4 className="title">Types of Cookies We Use</h4>
                                                <ul>
                                                    <li><b>Necessary Cookies:</b> These cookies are essential for the Website to
                                                        function properly. They enable basic features such as page
                                                        navigation, user authentication, and access to secure areas of the
                                                        Website. Without these cookies, the Website may not work as
                                                        intended.
                                                    </li>
                                                    <li><b>Performance Cookies:</b> These cookies help us understand how visitors
                                                        use the Website by collecting information about which pages are
                                                        visited most often and if any errors occur. This information is used
                                                        to improve the performance and user experience of the Website.
                                                    </li>
                                                    <li><b>Functionality Cookies:</b> These cookies allow the Website to remember
                                                        choices you make (such as language or region preferences) and
                                                        provide enhanced features. For example, they may be used to store
                                                        your login credentials so you don't have to enter them each time you
                                                        visit.
                                                    </li>
                                                    <li><b>Advertising and Targeting Cookies:</b> These cookies are used to deliver personalized advertisements based on your browsing behavior and interests. They also help measure the effectiveness of advertising campaigns. Third-party advertisers may set these cookies.</li>
                                                </ul>
                                                <h4 className="title">Third-Party Cookies</h4>
                                                <p>We may allow third-party service providers, such as analytics and advertising partners, to set cookies on the Website. These third parties may use cookies to collect information about your online activities across different websites for purposes such as analytics and targeted advertising. We do not control these third-party cookies, and their use is subject to their own privacy policies.</p>
                                                <h4 className="title">Managing Cookies</h4>
                                                <p>You can manage cookies through your browser settings. Most browsers allow you to control cookies by adjusting settings such as accepting or rejecting cookies, deleting cookies, and managing your cookie preferences. Keep in mind that disabling certain cookies may impact the functionality and user experience of the Website.</p>
                                                <h4 className="title">Updates to this Policy</h4>
                                                <p>We may update this Cookie Policy from time to time to reflect changes in our use of cookies or for other operational, legal, or regulatory reasons. The date of the latest revision will be indicated at the top of the Policy.</p>
                                            </> :
                                            null
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Legal;
