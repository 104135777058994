import {
    configureStore,
} from '@reduxjs/toolkit';
import dataSlice from './dataUploader/dataSlice';

export default configureStore({
    reducer: {
        dataUploader: dataSlice,
    },
})
