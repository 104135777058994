import React, {useState, useEffect} from 'react';
import {useDispatch} from "react-redux";
import GetConfigCategoriesData from "../../hooks/get-config-categories";

const About = () => {
    const dispatch = useDispatch();
    // eslint-disable-next-line no-unused-vars
    const [loading, setLoading] = useState(true);
    // eslint-disable-next-line no-unused-vars
    const [error, setError] = useState(null);

    useEffect(() => {
        GetConfigCategoriesData(setLoading, setError, null, dispatch);
        document.title = 'About Us';
    }, [dispatch]);

    const aboutInfoContent = [
        {
            iconName: 'capa.svg',
            title: 'Not just a website',
            content: 'We\'re your gateway to a world of exciting possibilities. With a passion for exploration and a keen eye for quality, we\'ve curated an expansive collection of products across various categories.'
        },
        {
            iconName: 'website.svg',
            title: 'Discover and Connect',
            content: 'Our platform isn\'t just about products; it\'s about discovery. We understand your unique needs and interests, so we\'ve designed a user-friendly interface that is easy to browse and choose products that resonate with your lifestyle.'
        },
        {
            iconName: 'shopping.svg',
            title: 'Your Shopping Companion',
            content: 'This place is your ultimate shopping guide on the web. Our platform is created to introduce you to a world of unique products without the hassle of traditional online shopping.'
        }
    ]

    const infoList = [
        {
            iconName: 'affordable.svg',
            title: 'Affordable shipping',
            content: 'Track your order every step of the way'
        },
        {
            iconName: 'phone-white-icon.svg',
            title: '24/7 Customer service',
            content: 'We\'re always here to assist you'
        },
        {
            iconName: 'parcel-white-icon.svg',
            title: 'Return guaranty',
            content: 'For free within 30 days of delivery'
        },
        {
            iconName: 'bucks-white-icon.svg',
            title: 'Transparent pricing',
            content: 'No hidden fees or surprises'
        },
    ]

    return (
        <main className="main-wrapper">
            <div className="axil-main-slider-area about-main-slider-area main-slider-style-7" style={{backgroundImage: 'url(/images/banner/banner-bg.webp)'}}>
            </div>
            <div className="axil-breadcrumb-area about-breadcrumb-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-8">
                            <div className="inner">
                                <h1 className="about-subtitle">About Our Store</h1>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-4"></div>
                    </div>
                </div>
            </div>
            <div className="axil-shop-area axil-section-gap bg-color-white">
                <div id='main' className="container">
                    <div className="row">
                        <div className="about-info-wrap">
                            <div className="wrap">
                                {
                                    aboutInfoContent.map((infoItem, index) => {
                                        const {iconName, title, content} = infoItem;

                                        return (
                                            <div className='about-info-item' key={index}>
                                                <div className="icon-wrap">
                                                    <img src={`/images/about-page/${iconName}`} alt="Icon"/>
                                                </div>
                                                <div className="content-wrap">
                                                    <h4>
                                                        {title}
                                                    </h4>
                                                    <p>
                                                        {content}
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>

                        <div className="about-image-info">
                            <div className="image-wrap">
                                <picture>
                                    <source srcSet={`/images/about-page/about-image-info-two.webp`} type="image/webp"/>
                                    <img src={`/images/about-page/about-image-info-two.png`} alt="About"/>
                                </picture>
                            </div>
                            <div className="content-wrap">
                                <h4>
                                    Your Trusted Guide
                                </h4>
                                <p>
                                    Choosing our site is choosing trust. It is our commitment to excellence that drives us to provide a platform that's both reliable and delightful. We've carefully selected partners who share our dedication to quality, ensuring that every link you click takes you to a world of authenticity and value. With us, you're not just shopping, you're embarking on a trusted adventure.
                                </p>
                                <div className="shop-btn">
                                    <a href="#main" className="axil-btn btn-bg-secondary right-icon">
                                        GET IN TOUCH
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="about-image-info image-is-right">
                            <div className="image-wrap">
                                <picture>
                                    <source srcSet={`/images/about-page/about-image-info-one.webp`} type="image/webp"/>
                                    <img src={`/images/about-page/about-image-info-one.png`} alt="About"/>
                                </picture>
                            </div>
                            <div className="content-wrap">
                                <h4>
                                    The best choice
                                </h4>
                                <p>
                                    Our commitment to excellence drives us to curate an expansive collection that caters to your diverse needs and desires. With a passion for exploration and a keen eye for quality, we've scoured the market to bring you a carefully selected range of products across various categories. Whether you're looking for fashion, electronics, home decor, or any other lifestyle essentials, we've got you covered.
                                </p>
                                <div className="shop-btn">
                                    <a href="#main" className="axil-btn btn-bg-secondary right-icon">
                                        GET IN TOUCH
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="services-info">
                    {infoList.map((item, index) => {
                        const {iconName, title, content} = item;

                        return (
                            <div className='service-item' key={index}>
                                <div className="icon-wrap">
                                    <img src={`/images/icons/${iconName}`} alt="Icon"/>
                                </div>
                                <div className="content-wrap">
                                    <h5>{title}</h5>
                                    <p>{content}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>

            </div>
        </main>
    )
}

export default About;
