import {getCategoryByIdService} from "../services/categories";
import {setCurrentCategory, setAllCategories} from "../redux/dataUploader/dataSlice";

const GetCategoriesData = async (categoriesArr, setLoading, setError, setData, currentCategoryData, dispatch) => {
    setLoading(true);
    const categoryDataArr = [];
    try {
        await Promise.all(categoriesArr.map(async ({ provider, id, path }) => {
            try {
                const results  = await getCategoryByIdService(provider, id);
                if(results['data'] && (results['data']['categories'].length > 0) || Object.keys(results['data']['categories']).length > 0) {
                    const newCategoryData = Object.assign(results['data']['categories'], {path: path});
                    categoryDataArr.push(newCategoryData);

                    categoryDataArr.forEach(el => {
                        if(+el.id === +currentCategoryData.id) {
                            dispatch(setCurrentCategory(el.children));
                        }
                    })
                }
            } catch (error) {
                setError(true);
                console.error('Error!', error);
            }
        }));

        const combinedData = categoryDataArr.sort((a, b) => a.name?.localeCompare(b.name));
        if(combinedData.length === 0) {
            setError(true)
        } else {
            setData(combinedData);
            dispatch(setAllCategories(combinedData));
        }
    } finally {
        setLoading(false);
    }

    function isJSON(str) {
        try {
            return (JSON.parse(str) && !!str);
        } catch (e) {
            return false;
        }
    }
}


export default GetCategoriesData;
