import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {useSearchParams} from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import {PRODUCT_TOP} from "../../constants";
import getProductProps from "../../helpers/get-product-props";
import 'swiper/css';
import 'swiper/css/navigation';

const TopProducts = ({data}) => {
    const [queryParameters] = useSearchParams();
    const [showFullDescriptions, setShowFullDescriptions] = useState([]);
    const currentCategory = useSelector(state => state.dataUploader.currentCategoryData);

    const toggleDescription = (productId) => {
        setShowFullDescriptions(prevState => {
            const newState = {...prevState};
            newState[productId] = !newState[productId];
            return newState;
        });
    };

    return (
        <>
            {data.length > 0 ?
                <div className="top-products-title section-title-wrapper">
                    <h2 className="title">Featured Products</h2>
                </div> :
                null
            }
            {window.innerWidth > 767 ?
                <div className="row row--15 top-products-container">
                    {
                        data.slice(0, 4).map((el,i) => {
                            const {id, brandName, title, productImage, productPrice, description, currencySymbol, brandImage} = el;
                            const {productFullLink, croppedTitle, showFullDescription, croppedDescription} = getProductProps(queryParameters, el, showFullDescriptions, PRODUCT_TOP, currentCategory.provider);
                            return (
                                <div className="col-xl-6 col-lg-6 col-sm-6" key={id + i}>
                                    <div className="axil-product product-style-one has-color-pick mt--40">
                                        <div className="thumbnail">
                                            <a href={productFullLink} target="_blank" rel="noreferrer">
                                                <img src={productImage} alt={title}/>
                                            </a>
                                            <div className="product-hover-action">
                                                <ul className="cart-action">
                                                    <li className="select-option">
                                                        <a href={productFullLink} target="_blank" rel="noreferrer">View Offer</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="product-content">
                                            <div className="inner top-products">
                                                {brandImage ?
                                                    <img className="product-brand-image" src={brandImage}
                                                         alt={brandName}/> : null}
                                                <h5 className="title">
                                                    <a href={productFullLink} target="_blank" rel="noreferrer" className={croppedTitle !== title ? 'add-popup': ''}>{croppedTitle}</a>
                                                    {croppedTitle !== title ?
                                                        <a className='title-popup' href={productFullLink} target="_blank" rel="noreferrer">{title}</a> :
                                                        null
                                                    }
                                                </h5>
                                                <div className="">
                                                    <div className="inner">
                                                        <p>
                                                            {showFullDescription ?
                                                                description :
                                                                croppedDescription
                                                            }
                                                            {description.length > 30 && (
                                                                <span className="read-more">
                                                                {showFullDescription ? (
                                                                    <button onClick={() => toggleDescription(id)}>Show less</button>
                                                                ) : (
                                                                    <button onClick={() => toggleDescription(id)}>Show more</button>
                                                                )}
                                                            </span>
                                                            )}
                                                        </p>
                                                    </div>
                                                    <div className="product-price-variant">
                                                <span
                                                    className="price current-price">{`${currencySymbol.length > 1 ? productPrice + ' ' + currencySymbol : currencySymbol + productPrice}`}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div> :
                <div className="carousel">
                    <Swiper
                        navigation={true}
                        modules={[Autoplay, Navigation]}
                        loop={true}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                        }}
                        breakpoints={{
                            100: {
                                spaceBetween: 20,
                                slidesPerView: 1,
                            },
                            768: {
                                slidesPerView: 'auto',
                            },
                        }}
                        className="mySwiper">
                        {data.slice(0, 4).map((el, i) => {
                            const {id, brandName, title, productImage, productPrice, description, currencySymbol, brandImage} = el;
                            const {productFullLink, croppedTitle, showFullDescription, croppedDescription} = getProductProps(queryParameters, el, showFullDescriptions, PRODUCT_TOP, currentCategory.provider);
                            return (
                                <SwiperSlide
                                    key={id + i}
                                    id={id}
                                    className="slide slide-item"
                                >
                                    <div className="col-xl-6 col-lg-4 col-sm-6">
                                        <div className="axil-product product-style-one has-color-pick mt--40">
                                            <div className="thumbnail">
                                                <a href={productFullLink} target="_blank" rel="noreferrer">
                                                    <img src={productImage} alt={title}/>
                                                </a>
                                                <ul className="cart-action">
                                                    <li className="select-option">
                                                        <a href={productFullLink} target="_blank" rel="noreferrer">View Offer</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="product-content">
                                                <div className="inner">
                                                    {brandImage ?
                                                        <img className="product-brand-image" src={brandImage}
                                                             alt={brandName}/> : null}
                                                    <h5 className="title" title={title}>
                                                        <a href={productFullLink} target="_blank" rel="noreferrer">{croppedTitle}</a>
                                                    </h5>
                                                    <div className="">
                                                        <div className="inner">
                                                            <p>
                                                                {croppedDescription}
                                                                {description.length > 110 && (
                                                                    <span className="read-more">
                                                                    {showFullDescription ? (
                                                                        <button onClick={() => toggleDescription(id)}>Show less</button>
                                                                    ) : (
                                                                        <button onClick={() => toggleDescription(id)}>Show more</button>
                                                                    )}
                                                                </span>
                                                                )}
                                                            </p>
                                                        </div>
                                                        <div className="product-price-variant">
                                                    <span
                                                        className="price current-price">{`${currencySymbol.length > 1 ? productPrice + ' ' + currencySymbol : currencySymbol + productPrice}`}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </div>
            }
        </>
    )
}

export default TopProducts;
