import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './redux/store';
import App from './components/App';

import './styles/css/vendor/font-awesome.css';
import './styles/css/vendor/flaticon/flaticon.css';
import './styles/css/vendor/slick.css';
import './styles/css/vendor/slick-theme.css';
import './styles/css/vendor/sal.css';
import './styles/css/vendor/magnific-popup.css';
import './styles/css/vendor/base.css';
import './styles/css/style.css';

let container = null;

document.addEventListener('DOMContentLoaded', function() {
    if (!container) {
        container = document.getElementById('root');
        const root = ReactDOM.createRoot(container)
        root.render(
            <Provider store={store}>
                <App />
            </Provider>
        );
    }
});