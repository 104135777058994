const extractUrlParams = (queryParams, type, pageValue) => {
    if(type === 'products') {
        let geo, size, sort, ascending, brand, shop, category, page;
        if(queryParams.size > 0) {
            geo = queryParams.get('geo');
            size = queryParams.get('size');
            sort = queryParams.get('sort');
            ascending = queryParams.get('ascending');
            brand = queryParams.get('brand');
            shop = queryParams.get('shop');
            category = queryParams.get('category');
            page = queryParams.get('page');
        }

        if(pageValue) page = pageValue;

        let params = (geo ? `geo=${geo}` : '') + (geo ? '&' : '') +
            (size ? `size=${size}` : 'size=8') + '&'+
            (sort ? `sort=${sort}` : '') + (sort ? '&' : '') +
            (brand ? `brand=${brand}` : '') + (brand ? '&' : '') +
            (shop ? `shop=${shop}` : '') + (shop ? '&' : '') +
            (ascending ? `ascending=${ascending}` : '') + (ascending ? '&' : '') + (page ? `page=${page}` : `page=1`);
        if(params.slice(-1) === '&') params = params.slice(0, -1);
        return {
            params: params,
            paramCategory: category
        };
    }
}

export default extractUrlParams;
