export function findCategoryNameById(data, id) {
    for (const item of data) {
        if (+item.id === +id) {
            return item;
        }
        if (item.children) {
            const found = findCategoryNameById(item.children, id);
            if (found) {
                return found;
            }
        }
    }
    return null;
}

export default findCategoryNameById;
