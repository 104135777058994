import {PRODUCT_MAIN, PRODUCT_TOP} from "../constants";

const getProductProps = (queryParameters, product, showFullDescriptions, type, provider) => {
    let croppedTitle;
    const {id, title, description, brandId, brandName, shopId, productLink} = product;
    const lid = queryParameters.get('lid');
    const gclid = queryParameters.get('gclid');
    const home = window.location.pathname == '/';
    const productFullLink = process.env.REACT_APP_API_URL + '/co/?l=' + productLink + `&product_id=${id}${brandId ? `&brand_id=${brandId}` : 'brand_id'}${brandName ? `&brand_name=${brandName}` : ''}${shopId ? `&shop_id=${shopId}` : ''}${lid ? `&lid=${lid}` : ''}${provider ? `&an=${provider}` : ''}${gclid ? `&gclid=${gclid}` : ''}&home=${home ? `1` : '0'}`;
    const showFullDescription = showFullDescriptions[id] || false;
    let num;
    if(type === PRODUCT_MAIN) {
        croppedTitle = window.innerWidth > 767 ? cropString(title, 25) : title;
        if (!showFullDescription && description.length > 75 && window.innerWidth > 767) {
            num = 75;
        } else if (!showFullDescription && description.length > 80 && window.innerWidth <= 767) {
            num = 80;
        }
    } else if(type === PRODUCT_TOP) {
        croppedTitle = window.innerWidth > 767 ? cropString(title, 50) : title;
        if (!showFullDescription && description.length > 150 && window.innerWidth > 767) {
            num = 150;
        } else if (!showFullDescription && description.length > 150 && window.innerWidth <= 767) {
            num = 110;
        }
    }
    const croppedDescription = cropString(description, num);

    return {
        productFullLink: productFullLink,
        showFullDescription: showFullDescription,
        croppedTitle: croppedTitle,
        croppedDescription: croppedDescription
    }
}

function cropString(str, maxLength) {
    if (str === '') {
        return str;
    }
    if (str.length <= maxLength) {
        return str;
    }
    let lastSpaceIndex = str.lastIndexOf(' ', maxLength);
    if (lastSpaceIndex === -1) return str.slice(0, maxLength) + '...';
    return str.slice(0, lastSpaceIndex) + '...';
}

export default getProductProps;
