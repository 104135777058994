import axios from "axios";

let baseURL;
// const env = process.env.REACT_APP_ENV;
const internalURL = '/';
// if(env === 'production' || !env) {
//     baseURL = 'https://api.optim8.io/api/';
// } else if(env === 'development') {
//     baseURL = 'https://dev-api.optim8.io/api/';
// } else {
//     baseURL = 'https://dev-api.optim8.io/api/';
//     // const baseURL = 'http://188.166.162.59/api/';
// }
baseURL = process.env.REACT_APP_API_URL + '/api/';
export const baseFetch = axios.create({withCredentials: true, baseURL: baseURL});
export const internalFetch = axios.create({internalURL}, {credentials: 'omit'});
