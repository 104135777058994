import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useSearchParams} from "react-router-dom";
import {MONTH_NAMES} from "../../constants";
import extractUrlParams from "../../helpers/extract-url-params";
import GetProductsData from "../../hooks/get-products";
import Banner from "../../components/Banner";
import Products from "../../components/Products";
import TopProducts from "../../components/TopProducts";

const Home = ({categoriesList, categoriesError}) => {
    const dispatch = useDispatch();
    const {pathname} = useLocation();
    const [queryParameters] = useSearchParams();
    const banner = useSelector(state => state.dataUploader.banner);
    const currentCategory = useSelector(state => state.dataUploader.currentCategoryData);
    const defaultCategory = useSelector(state => state.dataUploader.defaultCategoryData);
    const headerCurrentCategory = useSelector(state => state.dataUploader.headerCurrentCategory);
    const allCategories = useSelector(state => state.dataUploader.allCategories);
    const [date, setDate] = useState('');
    const [productsList, setProductsList] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [dataPage, setDataPage] = useState(2);
    const [loading, setLoading] = useState(true);
    const [loadingProducts, setLoadingProducts] = useState(false);
    const [error, setError] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [errorMessage, setErrorMessage] = useState('');
    const triggerLoadingMoreProducts = useRef(null);

    useEffect(() => {
        document.title = 'Home';
    }, [pathname]);

    useEffect(() => {
        const today = new Date();
        const currentMonth = MONTH_NAMES[today.getMonth()];
        const currentDay = today.getDate();
        setDate(`${currentMonth} ${currentDay}`);
    }, []);

    useEffect(() => {
        let id, provider;
        const {params, paramCategory} = extractUrlParams(queryParameters, 'products');
        const subCategoryId = pathname === '/' ? null : localStorage.getItem('categoryId');
        const subCategoryProvider = pathname === '/' ? null : localStorage.getItem('categoryProvider');
        setHasMore(false);
        setDataPage(2);
        if (paramCategory) {
            id = paramCategory;
            headerCurrentCategory.provider !== '' ? provider = headerCurrentCategory.provider : provider = currentCategory.provider;
        } else if(headerCurrentCategory && headerCurrentCategory.id !== '') {
            id = headerCurrentCategory.id;
            provider = headerCurrentCategory.provider;
        } else if(subCategoryId && subCategoryId !== '' && subCategoryProvider && subCategoryProvider !== '' && currentCategory.id !== '') {
            id = subCategoryId;
            provider = subCategoryProvider;
        } else if(!subCategoryId && !subCategoryProvider && currentCategory && currentCategory.id !== '') {
            id = currentCategory.id;
            provider = currentCategory.provider;
        }

        const emergyParams = [];
        allCategories.some((e) => {
            if (e.categories.some((eInner) => eInner.id == id)) {
                emergyParams.push({category: e.id, provider: e.provider})
                return true;
            }
            return false;
        });
        if (id && defaultCategory && defaultCategory.id && id != defaultCategory.id) {
            emergyParams.push({category: defaultCategory.id, provider: defaultCategory.provider})
        }

        if(allCategories.length > 0 && (headerCurrentCategory.id !== '' || subCategoryId !== '')){
            if (headerCurrentCategory.updateProducts !== false) {
                GetProductsData(provider, setLoading, setError, setErrorMessage, setProductsList, params, id, 'products', setHasMore, dispatch, emergyParams);
            }
        }
    }, [allCategories, queryParameters, currentCategory, headerCurrentCategory]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <main className="main-wrapper">
            {banner && window.innerWidth > 992 ?
                <Banner titleText={`Hottest Deals For: ${date}`} /> : null
            }
            {!error && !categoriesError ?
                <div className="axil-shop-area axil-section-gap bg-color-white main-container">
                    <div id='main' className="container">
                        {window.innerWidth <= 992 ?
                            <div className="products-title section-title-wrapper">
                                <h2 className="title">Hottest Deals For: {date}</h2>
                            </div> :
                            null
                        }
                        {!loading ?
                            <Products
                                categories={categoriesList}
                                data={productsList}
                                setData={setProductsList}
                                triggerLoadingMoreProducts={triggerLoadingMoreProducts}
                                loadingProducts={loadingProducts}
                                setLoadingProducts={setLoadingProducts}
                                hasMore={hasMore}
                                setHasMore={setHasMore}
                                dataPage={dataPage}
                                setDataPage={setDataPage}
                            /> :
                            <div className="loader-container">
                                <span className="loader"></span>
                            </div>
                        }
                        {!loading ?
                            <TopProducts
                                data={productsList}
                            /> :
                            null
                        }
                    </div>
                </div> :
                <div className="axil-shop-area axil-section-gap bg-color-white">
                    <div id='main' className="container">
                        <div>{errorMessage}</div>
                    </div>
                </div>
            }
        </main>
    )
}

export default Home;
