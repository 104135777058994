export const LEGAL_PAGES = {
    PRIVACY : {
        link: 'privacy',
        title: 'Privacy Policy'
    },
    TERMS : {
        link: 'terms',
        title: 'Terms and Conditions'
    },
    COOKIE : {
        link: 'cookie',
        title: 'Cookie Policy'
    }
}

export const PAGES = ['/about', `/${LEGAL_PAGES.PRIVACY.link}`, `/${LEGAL_PAGES.TERMS.link}`, `/${LEGAL_PAGES.COOKIE.link}`]
export const MONTH_NAMES = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
export const PRODUCT_MAIN = 'main';
export const PRODUCT_TOP = 'top';
