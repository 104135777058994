import React, {useState, useEffect} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import MainRouter from "../routes/Router";
import {ErrorBoundary} from 'react-error-boundary';
import ErrorFallback from './ErrorFallback';
import Header from "./Header";
import Footer from "./Footer";
import CookiePopup from "./popups/CookiePopup";
import {
    setBanner,
    setCurrentCategoryData,
    setDefaultCategoryData,
    setMainCategories
} from "../redux/dataUploader/dataSlice";
import GetConfigCategoriesData from "../hooks/get-config-categories";
import GetCategoriesData from "../hooks/get-categories";

const App = () => {
    const dispatch = useDispatch();
    let isRenderPopupStorage = localStorage.getItem("isRenderPopup");
    const mainCategories = useSelector(state => state.dataUploader.mainCategories);
    const currentCategoryData = useSelector(state => state.dataUploader.currentCategoryData);
    const defaultCategoryData = useSelector(state => state.dataUploader.defaultCategoryData);
    const [categoriesList, setCategoriesList] = useState([]);
    const [isRenderPopup, setRenderPopup] = useState(null);
    const [isVisiblePopup, setVisiblePopup] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        GetConfigCategoriesData(
            setLoading,
            setError,
            categories => {
                dispatch(setMainCategories(categories));
            },
            category => {
                dispatch(setCurrentCategoryData({
                    provider: category.provider,
                    id: category.id
                }));
            },
            bannerData => {
                dispatch(setBanner(bannerData));
            },
            defaultCategory => {
                dispatch(setDefaultCategoryData({
                    provider: defaultCategory.provider,
                    id: defaultCategory.id
                }));
            },
        );
    }, [dispatch, setLoading, setError, setMainCategories, setCurrentCategoryData, setDefaultCategoryData]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (mainCategories.length > 0) GetCategoriesData(mainCategories, setLoading, setError, setCategoriesList, currentCategoryData, dispatch);
    }, [mainCategories, dispatch]);

    useEffect(() => {
        let isRenderPopupStorageCopy = isRenderPopupStorage;
        if (isRenderPopupStorageCopy === null) {
            localStorage.setItem("isRenderPopup", '1');
            isRenderPopupStorageCopy = '1';
        }

        if (Number(isRenderPopupStorageCopy)) {
            setRenderPopup(true);
            setTimeout(() => {
                setVisiblePopup(true);
            }, 2000)
        } else {
            setRenderPopup(false);
            setVisiblePopup(false);
        }

    }, [isRenderPopupStorage]);

    const handleVisibleCookiePopup = () => {
        localStorage.setItem("isRenderPopup", '0');
        setVisiblePopup(false);
    };

    return (
        <Router>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Header data={categoriesList}/>
                <MainRouter
                    categoriesList={categoriesList}
                    categoriesError={error}
                />
                <Footer categoriesList={categoriesList}/>
                {isRenderPopup && (
                    <CookiePopup
                        visible={isVisiblePopup}
                        handleVisibleCookiePopup={handleVisibleCookiePopup}
                    />
                )}
            </ErrorBoundary>
        </Router>
    )
}

export default App;
