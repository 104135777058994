import React, {useEffect, useState} from 'react';
import {useSearchParams} from "react-router-dom";

const Footer = ({categoriesList}) => {
    const [num, setNum] = useState(0);
    const [queryParameters] = useSearchParams();
    const queryParams = []
    if (queryParameters.get('gclid')) {
        queryParams.push('gclid=' + queryParameters.get('gclid'))
    }
    useEffect(() => {
        if(categoriesList.length > 6 && categoriesList.length) {
            setNum(categoriesList.length / 2 + 0.5);
        } else if(categoriesList.length > 6) {
            setNum(categoriesList.length / 2);
        }
    }, [categoriesList])

    const removeTitle = () => {
        localStorage.removeItem('categoryName');
        localStorage.removeItem('categoryId');
        localStorage.removeItem('categoryProvider');
    }

    return (
        <footer className="axil-footer-area footer-style-2">
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-sm-12">
                            <div className="axil-footer-widget logo-wrap">
                                <a href={"/" + (queryParams.length > 0 ? ('?' + queryParams.join('&')) : '')} onClick={removeTitle} className="logo logo-dark">
                                    <img src={`/images/logo/logo.png`} alt="Site Logo"/>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-6 footer-column">
                            <div className="col-lg-12 col-sm-12">
                                <div className="axil-footer-widget">
                                    <h5 className="widget-title">Pages</h5>
                                    <div className="inner">
                                        <ul>
                                            <li><a href={"/about" + window.location?.search}>About Us</a></li>
                                            <li><a href={"/privacy" + window.location?.search}>Privacy Policy</a></li>
                                            <li><a href={"/terms" + window.location?.search}>Terms Of Use</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-sm-6 col-6 footer-column">
                            <div className="col-lg-12 col-sm-12">
                                <div className="axil-footer-widget">
                                    <h5 className="widget-title">Category</h5>
                                    <div className="inner">
                                        <ul className={categoriesList.length > 6 ? 'two-col-list' : ''}>
                                            {categoriesList.length > 6 ?
                                                <>
                                                    <li className="left-block">
                                                        <ul>
                                                            {categoriesList.slice(0, num).map(itm => (
                                                                <li key={itm.id}><a href={process.env.REACT_APP_API_URL  + itm.path + (queryParams.length > 0 ? ('?' + queryParams.join('&')) : '')} onClick={removeTitle}>{itm.name}</a></li>
                                                            ))}
                                                        </ul>
                                                    </li>
                                                    <li className="right-block">
                                                        <ul>
                                                            {categoriesList.slice(num, categoriesList.length).map(itm => (
                                                                <li key={itm.id}><a href={process.env.REACT_APP_API_URL  + itm.path + (queryParams.length > 0 ? ('?' + queryParams.join('&')) : '')} onClick={removeTitle}>{itm.name}</a></li>
                                                            ))}
                                                        </ul>
                                                    </li>
                                                </> :
                                                categoriesList.map(itm => (
                                                    <li key={itm.id}><a href={itm.path}>{itm.name}</a></li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright-area copyright-default">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="copyright-left d-flex flex-wrap">
                            <ul className="quick-link">
                                <li>© {new Date().getFullYear()}. All rights reserved by <a href={"/" + (queryParams.length > 0 ? ('?' + queryParams.join('&')) : '')} onClick={removeTitle}>Rainbowsoffers</a>.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
