import { createSlice } from '@reduxjs/toolkit';
import initialState from "../initialState";

export const dataSlice = createSlice({
    name: 'dataUploader',
    initialState: initialState,
    reducers: {
        setBanner: (state, action) => {
            state.banner = action.payload;
        },
        setAllCategories: (state, action) => {
            state.allCategories = action.payload;
        },
        setMainCategories: (state, action) => {
            state.mainCategories = action.payload;
        },
        setSelectedCategory: (state, action) => {
            state.selectedCategory = action.payload;
        },
        setCurrentCategoryData: (state, action) => {
            state.currentCategoryData = action.payload;
        },
        setCurrentCategory: (state, action) => {
            state.currentCategory = action.payload;
        },
        setHeaderCurrentCategory: (state, action) => {
            state.headerCurrentCategory = action.payload;
        },
        setDefaultCategoryData: (state, action) => {
            state.defaultCategoryData = action.payload;
        },
        setCategoryName: (state, action) => {
            state.categoryName = action.payload;
        },
    },
});

export const { setBanner, setSelectedCategory, setMainCategories, setCurrentCategoryData, setCurrentCategory, setAllCategories, setHeaderCurrentCategory, setDefaultCategoryData, setCategoryName } = dataSlice.actions;

export default dataSlice.reducer;
